import {get, set} from 'idb-keyval';

import {v4 as uuid} from 'uuid';

import {Timer, TimerData} from '../../models/timer';
import {Client} from '../../models/client';
import {TIMERS_LIMIT} from '../../constants/index';

export class TimersService {
  private static instance: TimersService;

  private constructor() {
    // Private constructor, singleton
  }

  static getInstance() {
    if (!TimersService.instance) {
      TimersService.instance = new TimersService();
    }
    return TimersService.instance;
  }

  private sortTimersByNewestFirst(timers: Timer[]) {
    return timers.sort((a, b) => new Date(b.data.updated_at).getTime() - new Date(a.data.updated_at).getTime());
  }

  create(data: TimerData): Promise<Timer> {
    return new Promise<Timer>(async (resolve, reject) => {
      try {
        if (!data || data === undefined) {
          reject('Timer not defined.');
          return;
        }

        let timers: Timer[] = await get('timers');

        if (!timers || timers.length <= 0) {
          timers = [];
        }

        const activeTimers = timers.filter((timer) => timer.isActive);

        const areTimersLimitReached = activeTimers.length >= TIMERS_LIMIT;
        if (areTimersLimitReached) {
          reject('Timers max limit reached.');
          return;
        }

        const now: number = new Date().getTime();

        data.created_at = now;
        data.updated_at = now;

        const timer: Timer = {
          id: uuid(),
          data: data,
          isActive: true,
        };

        timers.push(timer);

        await set('timers', timers);

        resolve(timer);
      } catch (err) {
        reject(err);
      }
    });
  }

  list(): Promise<Timer[]> {
    return new Promise<Timer[]>(async (resolve, reject) => {
      try {
        let timers: Timer[] = await get('timers');

        if (!timers || timers.length <= 0) {
          resolve([]);
          return;
        }

        timers = this.sortTimersByNewestFirst(timers);

        resolve(timers);
        return;
      } catch (err) {
        reject(err);
      }
    });
  }

  find(id: string | undefined): Promise<Timer | undefined> {
    return new Promise<Timer | undefined>(async (resolve) => {
      try {
        const timers: Timer[] = await get('timers');

        if (!timers || timers.length <= 0) {
          resolve(undefined);
          return;
        }

        const timer: Timer | undefined = timers.find((filteredTimer: Timer) => {
          return filteredTimer.id === id;
        });

        resolve(timer);
      } catch (err) {
        resolve(undefined);
      }
    });
  }

  listForClient(clientId: string): Promise<Timer[]> {
    return new Promise<Timer[]>(async (resolve, reject) => {
      try {
        if (!clientId || clientId === undefined) {
          reject('Client not defined.');
          return;
        }

        const timers: Timer[] = await get('timers');

        if (!timers || timers.length <= 0) {
          resolve([]);
          return;
        }

        const filteredTimers: Timer[] = timers.filter((timer: Timer) => {
          return timer.data.client !== undefined && timer.data.client.id === clientId;
        });

        if (!filteredTimers || filteredTimers.length <= 0) {
          resolve([]);
          return;
        }

        resolve(filteredTimers);
      } catch (err) {
        reject(err);
      }
    });
  }

  updateForClient(client: Client): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        if (!client || !client.id) {
          reject('Client not defined.');
          return;
        }

        const timers: Timer[] = await get('timers');

        if (!timers || timers.length <= 0) {
          resolve();
          return;
        }

        timers.forEach((timer: Timer) => {
          if (timer.data.client && timer.data.client.id === client.id) {
            timer.data.client = {
              id: client.id as string,
              name: client.data.name,
              color: client.data.color as string,
            };

            timer.data.updated_at = new Date().getTime();
          }
        });

        await set('timers', timers);

        resolve();
      } catch (err) {
        reject(err);
      }
    });
  }

  update(timer: Timer | undefined): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        if (!timer || !timer.data) {
          reject('Timer is not defined.');
          return;
        }

        const timers: Timer[] = await get('timers');

        if (!timers || timers.length <= 0) {
          reject('No timers found.');
          return;
        }

        const index: number = timers.findIndex((filteredTimer: Timer) => {
          return filteredTimer.id === timer.id;
        });

        if (index < 0) {
          reject('Timer not found.');
          return;
        }

        timer.data.updated_at = new Date().getTime();

        timers[index] = timer;

        await set(`timers`, timers);

        resolve();
      } catch (err) {
        reject(err);
      }
    });
  }

  delete(timer: Timer): Promise<Timer[]> {
    return new Promise<Timer[]>(async (resolve, reject) => {
      try {
        if (!timer) {
          reject('Timer is not defined.');
          return;
        }

        let timers: Timer[] = await get('timers');

        if (!timers) {
          reject('No timers found.');
          return;
        }

        const indexOfTimer = timers.findIndex((filteredTimer) => filteredTimer.id === timer.id);
        if (indexOfTimer < 0) {
          reject("Timer doesn't exist in the database.");
          return;
        }

        const deactivatedTimer = {
          ...timer,
          isActive: false,
        };

        timers[indexOfTimer] = deactivatedTimer;

        await set(`timers`, timers);

        timers = this.sortTimersByNewestFirst(timers);

        resolve(timers);
      } catch (err) {
        reject(err);
      }
    });
  }
}
