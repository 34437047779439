import React, {useState} from 'react';

import {IonLabel, IonTextarea, IonItem, IonButton, IonLoading, IonToast, IonInput} from '@ionic/react';

import {useCookies} from 'react-cookie';

import {useTranslation} from 'react-i18next';

import styles from './FeedbackModal.module.scss';

import Ratings from 'react-ratings-declarative';

import {FeedbackFormInfo} from '../../models/feedback';

import {FeedbackService} from '../../services/feedback/feedback.service';

interface Props {
  closeAction: () => void;
  showThanksToastAction: () => void;
}

const FeedbackModal = (props: Props) => {
  const {t} = useTranslation(['common', 'feedback']);

  const [cookies, setCookie] = useCookies(['sent-feedback', 'email']);

  const [rating, setRating] = useState<string | undefined>(undefined);
  const [feedback, setFeedback] = useState('');
  const [email, setEmail] = useState<string | undefined>('');
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorToastVisible, setIsErrorToastVisible] = useState(false);

  const submit = async () => {
    setIsLoading(true);

    try {
      const feedbackFormInfo: FeedbackFormInfo = {
        rating,
        email,
        feedback,
      };

      await FeedbackService.getInstance().submit(feedbackFormInfo);

      setCookie('sent-feedback', 'true');

      setCookie('email', email);

      props.closeAction();
      props.showThanksToastAction();
    } catch (err) {
      console.error(err);
      setIsErrorToastVisible(true);
    }

    setIsLoading(false);
  };

  return (
    <>
      <IonLoading mode="ios" isOpen={isLoading} message={t('common:actions.wait')} />

      <IonToast
        isOpen={isErrorToastVisible}
        onDidDismiss={() => setIsErrorToastVisible(false)}
        message={t('common:feedback.error')}
        duration={400}
      />

      <div className={`${styles.container} ion-padding`}>
        <h1>{t('feedback:title')}</h1>
        <IonLabel className="ion-margin-bottom ion-text-center">{t('feedback:subtitle')}</IonLabel>
        <IonLabel className="ion-margin-vertical ion-text-center">{t('feedback:rating')}</IonLabel>
        <div className="ion-margin-bottom">
          <Ratings
            widgetRatedColors="rgb(230, 67, 47)"
            widgetDimensions="30px"
            rating={rating}
            changeRating={setRating}>
            <Ratings.Widget />
            <Ratings.Widget />
            <Ratings.Widget />
            <Ratings.Widget />
            <Ratings.Widget />
          </Ratings>
        </div>
        <IonLabel className="ion-margin-vertical ion-text-center">{t('feedback:email')}:</IonLabel>
        <IonItem className={`${styles.textInputItem} ion-margin-bottom`}>
          <IonInput value={email} type="email" onIonChange={($event) => setEmail($event.detail.value)} mode="md" />
        </IonItem>
        <IonLabel className="ion-margin-vertical ion-padding-top ion-text-center">{t('feedback:feedback')}</IonLabel>
        <IonItem className={`${styles.textareaItem} ion-margin-bottom`}>
          <IonTextarea
            placeholder={t('feedback:optional')}
            value={feedback}
            onIonChange={($event) => setFeedback($event.detail.value)}
          />
        </IonItem>
        <div className="actions">
          <IonButton
            color="card"
            className="ion-margin-end"
            type="submit"
            disabled={!rating || !email}
            onClick={submit}>
            <IonLabel>{t('common:actions.submit')}</IonLabel>
          </IonButton>

          <button type="button" onClick={props.closeAction}>
            {t('common:actions.cancel')}
          </button>
        </div>
      </div>
    </>
  );
};

export default FeedbackModal;
