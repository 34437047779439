import React, {useState} from 'react';

import {IonInput, IonList, IonItem} from '@ionic/react';

import styles from './BaseAutosuggest.module.scss';

interface Props {
  reference: string[];
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  forType: 'client' | 'project';
}

const BaseAutosuggest = (props: Props) => {
  const [suggestions, setSuggestions] = useState<string[]>(props.reference);

  const getSuggestions = (value: string): string[] => {
    if (!value) return props.reference;

    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (inputLength === 0) return props.reference;

    return props.reference.filter((item: string) => item.toLowerCase().slice(0, inputLength) === inputValue);
  };

  const handleChange = (newValue: string) => {
    setSuggestions(getSuggestions(newValue));
    props.setValue(newValue);
  };

  return (
    <div className={styles.container}>
      <IonInput
        minlength={3}
        maxlength={32}
        required={true}
        input-mode="text"
        onIonChange={({detail: {value}}) => handleChange(value)}
        value={props.value}
        name={props.forType}></IonInput>

      {renderAutosuggest()}
    </div>
  );

  function renderAutosuggest() {
    if (!props.reference || props.reference.length <= 0) return;

    return (
      <IonList className={styles.listContainer}>
        {suggestions.map((suggestion, index) => (
          <IonItem
            key={index}
            className={`ion-activatable ${styles.listItem}`}
            onClick={() => handleChange(suggestion)}>
            {suggestion}
          </IonItem>
        ))}
      </IonList>
    );
  }
};

export default BaseAutosuggest;
