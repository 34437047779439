import {createSelector} from 'reselect';
import {RootState} from '../reducers/index';

const getTimers = (state: RootState) => state.timers.timers;

export const getActiveTimers = createSelector([getTimers], (timers) => {
  if (timers === undefined) return undefined;

  if (!timers) return [];

  const activeTimers = timers.filter((timer) => timer.isActive);
  return activeTimers;
});
