import React from 'react';
import {useSelector} from 'react-redux';

import {useTranslation} from 'react-i18next';

import {lightFormat} from 'date-fns';

import DateFnsUtils from '@date-io/date-fns';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';

import {IonList, IonButton} from '@ionic/react';

import styles from './Entries.module.scss';

import {EntryItem as EntryItemStore} from '../../store/interfaces/entry.item';

import {rootConnector, RootProps} from '../../store/thunks/index.thunks';
import {RootState} from '../../store/reducers';

import EntryItem from '../entryitem/EntryItem';

import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';

import {format} from '../../utils/utils.date';

import ExportButton from '../export/ExportButton';

const Entries: React.FC<RootProps> = (props: RootProps) => {
  const {t} = useTranslation('entries');

  const entries: EntryItemStore[] | undefined = useSelector((state: RootState) => state.entries.entryItems);
  const selectedDay: Date = useSelector((state: RootState) => state.entries.entryItemsSelectedDate);

  function openDatePicker() {
    const input: HTMLInputElement | null = document.querySelector('input.MuiInputBase-input');

    if (!input) return;

    input.click();
  }

  async function selectDate(day: Date) {
    await props.listEntries(day);
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className="ion-padding-top">
        <h1>{t('entries.title')}</h1>
        {renderEntriesInfo()}

        {renderActions()}

        <IonList>{renderEntriesItems()}</IonList>
      </div>
    </MuiPickersUtilsProvider>
  );

  function renderActions() {
    return (
      <div className={styles.action}>
        <IonButton onClick={() => openDatePicker()} fill="outline" color="medium" size="small" aria-label={t('entries.select_date')}>
          {t('entries.select_date')}
        </IonButton>
        <ExportButton />
      </div>
    );
  }

  function renderEntriesInfo() {
    if (!entries || entries.length <= 0) {
      return renderDatePicker('entries.empty');
    }

    return renderDatePicker('entries.label');
  }

  function renderDatePicker(label: string) {
    return (
      <>
        <p className="placeholder" dangerouslySetInnerHTML={{__html: t(label, {selectedDate: format(selectedDay)})}}></p>
        <div className={styles.picker}>
          <DatePicker
            DialogProps={{disableEnforceFocus: true}}
            value={selectedDay}
            onChange={(date: MaterialUiPickersDate) => selectDate(date as Date)}
            format="yyyy/MM/dd"
          />
        </div>
      </>
    );
  }

  function renderEntriesItems() {
    if (!entries || entries.length <= 0) {
      return undefined;
    }

    const entriesDay: string = lightFormat(selectedDay, 'yyyy-MM-dd');

    return entries.map((entry: EntryItemStore) => {
      return <EntryItem entry={entry} entriesDay={entriesDay} key={`entry-${entry.id}`}></EntryItem>;
    });
  }
};

export default rootConnector(Entries);
