import React from 'react';
import {useSelector} from 'react-redux';

import {IonHeader, IonTitle, IonToolbar, IonButtons, IonIcon} from '@ionic/react';
import {sunnySharp, moonSharp} from 'ionicons/icons';
import styles from './Header.module.scss';

import {RootState} from '../../store/reducers';
import {rootConnector, RootProps} from '../../store/thunks/index.thunks';

const Header: React.FC<RootProps> = (props: RootProps) => {
  const isDark: boolean = useSelector((state: RootState) => state.theme.dark);

  return (
    <IonHeader>
      <IonToolbar>
        <IonTitle>
          <div className={styles.title}>
            <span>Time Tracker</span>
          </div>
        </IonTitle>
        <IonButtons slot="end">
          <IonIcon onClick={props.switchTheme} className={`ion-margin-end ion-activatable ${styles.themeButton}`} icon={isDark ? sunnySharp : moonSharp} />
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default rootConnector(Header);
