import i18next from 'i18next';

export function xlsxLabels() {
  return {
    total: i18next.t('export:total'),
    total_billable_hours: i18next.t('export:total_billable_hours'),
    start_date: i18next.t('export:start_date'),
    start_time: i18next.t('export:start_time'),
    end_date: i18next.t('export:end_date'),
    end_time: i18next.t('export:end_time'),
    duration: i18next.t('export:duration'),
    billable: i18next.t('export:billable'),
    client: i18next.t('export:client'),
    project: i18next.t('export:project'),
    activity: i18next.t('export:activity'),
  };
}
