import React, {FormEvent, RefObject, CSSProperties, useState, useEffect, useRef} from 'react';

import {
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonCheckbox,
  IonToast,
} from '@ionic/react';

import BaseAutosuggest from '../../components/generic/autosuggest/BaseAutosuggest';

import {useTranslation} from 'react-i18next';

import {ellipsisHorizontal, ellipsisVertical, close} from 'ionicons/icons';

import styles from './CreateTimerModal.module.scss';

import {TimerData} from '../../models/timer';
import {RootProps, rootConnector} from '../../store/thunks/index.thunks';

import {contrast} from '../../utils/utils.color';
import {IonAlert} from '@ionic/react';

interface Props extends RootProps {
  closeAction: Function;
  isTouring: boolean;
}

const CreateTimerModal: React.FC<Props> = (props: Props) => {
  const {projects, clients} = props;

  const {t} = useTranslation(['common', 'timers']);

  const [clientInput, setClientInput] = useState('');
  const [projectInput, setProjectInput] = useState('');

  const activityRef: RefObject<any> = useRef();
  const timerColorRef: RefObject<any> = useRef();
  const [isTimerBillable, setIsTimerBillable] = useState(false);
  const [timerColor, setTimerColor] = useState('#1f0d24');

  const [isTimerValid, setIsTimerValid] = useState(false);

  const [isErrorToastVisible, setIsErrorToastVisible] = useState(false);

  const [isCantCloseErrorVisible, setIsCantCloseErrorVisible] = useState(false);

  useEffect(() => {
    const ref = timerColorRef.current;

    ref.addEventListener('colorChange', ($event: CustomEvent) => setTimerColor($event.detail.hex), false);

    return () =>
      ref.removeEventListener('colorChange', ($event: CustomEvent) => setTimerColor($event.detail.hex), true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateTimer = () => {
    const isTimerValid = activityRef?.current?.value && clientInput && projectInput && typeof timerColor === 'string';
    setIsTimerValid(isTimerValid);
  };

  useEffect(() => {
    validateTimer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientInput, projectInput]);

  async function handleSubmit($event: FormEvent<HTMLFormElement>) {
    $event.preventDefault();

    if (!isTimerValid) return;

    try {
      const timerData: TimerData = {
        client: clientInput,
        project: projectInput,
        activity: activityRef.current.value,
        billable: isTimerBillable,
        color: timerColor,
      };

      await props.createTimer(timerData);

      props.closeAction();
    } catch (err) {
      console.error(err);
      setIsErrorToastVisible(true);
    }
  }

  const handleClose = () => {
    if (props.isTouring) {
      setIsCantCloseErrorVisible(true);
      return;
    }

    props.closeAction();
  };

  return renderContent();

  function renderContent() {
    const colorContrast: string = contrast(timerColor, 128);

    return (
      <IonContent>
        <IonAlert
          isOpen={isCantCloseErrorVisible}
          onDidDismiss={() => setIsCantCloseErrorVisible(false)}
          header="Oops!"
          subHeader={t('timers:create.cantleave')}
          buttons={['OK']}
        />

        <IonToast
          isOpen={isErrorToastVisible}
          onDidDismiss={() => setIsErrorToastVisible(false)}
          message="Something went wrong :( Please try again."
          duration={400}
        />

        <IonHeader>
          <IonToolbar
            style={
              {
                '--background': timerColor,
                '--color': colorContrast,
                '--ion-toolbar-color': colorContrast,
              } as CSSProperties
            }>
            <IonTitle>{t('timers:create.title')}</IonTitle>
            <IonButtons slot="start">
              <IonButton onClick={handleClose}>
                <IonIcon icon={close} slot="icon-only"></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <main className="ion-padding">
          <form className="tour-second-step" onSubmit={($event: FormEvent<HTMLFormElement>) => handleSubmit($event)}>
            <IonList className="inputs-list">
              <div className={styles.projectClientInputContainer}>
                <div>
                  <IonItem className="input-title">
                    <IonLabel>{t('timers:create.client')}</IonLabel>
                  </IonItem>
                  <IonItem>
                    <BaseAutosuggest
                      reference={clients}
                      value={clientInput}
                      setValue={setClientInput}
                      forType="client"
                    />
                  </IonItem>
                </div>
                <div>
                  <IonItem className="input-title">
                    <IonLabel>{t('timers:create.project')}</IonLabel>
                  </IonItem>
                  <IonItem>
                    <BaseAutosuggest
                      reference={projects}
                      value={projectInput}
                      setValue={setProjectInput}
                      forType="project"
                    />
                  </IonItem>
                </div>
              </div>

              <IonItem className="input-title ion-margin-top">
                <IonLabel>{t('timers:create.activity')}</IonLabel>
              </IonItem>
              <IonItem>
                <IonInput
                  ref={activityRef}
                  debounce={500}
                  minlength={3}
                  maxlength={32}
                  required={true}
                  input-mode="text"
                  onIonChange={() => validateTimer()}></IonInput>
              </IonItem>

              <IonItem className="ion-margin-top">
                <IonCheckbox
                  style={{'--border-color': '#39273e', '--border-width': 3}}
                  color="secondary"
                  mode="ios"
                  checked={isTimerBillable}
                  onIonChange={({detail: {checked}}) => setIsTimerBillable(checked)}
                />
                <IonLabel className="ion-margin-horizontal">{t('timers:create.billable')}</IonLabel>
              </IonItem>

              <IonItem className="input-title ion-margin-top">
                <IonLabel>{t('timers:create.color')}</IonLabel>
              </IonItem>

              <div className={styles.color}>
                <deckgo-color
                  ref={timerColorRef}
                  colorHex={timerColor}
                  className="ion-padding-start ion-padding-end ion-padding-bottom"
                  more={true}
                  label={false}>
                  <IonIcon
                    ios={ellipsisHorizontal}
                    md={ellipsisVertical}
                    slot="more"
                    aria-label="More"
                    class="more"></IonIcon>
                </deckgo-color>
              </div>
            </IonList>

            <div className="actions">
              <IonButton
                type="submit"
                disabled={!isTimerValid}
                style={
                  {
                    '--background': timerColor,
                    '--color': colorContrast,
                    '--background-hover': timerColor,
                    '--color-hover': colorContrast,
                    '--background-activated': colorContrast,
                    '--color-activated': timerColor,
                  } as CSSProperties
                }>
                <IonLabel>{t('common:actions.submit')}</IonLabel>
              </IonButton>

              <button type="button" onClick={handleClose}>
                {t('common:actions.cancel')}
              </button>
            </div>
          </form>
        </main>
      </IonContent>
    );
  }
};

export default rootConnector(CreateTimerModal);
