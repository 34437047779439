import * as moment from 'moment';

export function formatDuration(valueInMiliseconds: number | undefined): string {
  if (!valueInMiliseconds || valueInMiliseconds === undefined) {
    return `0h 0min`;
  }

  const duration = moment.duration(valueInMiliseconds);

  let hours: number | string = duration.hours();
  let minutes: number | string = duration.minutes();

  const addZeroIfLessThanTen = (number: number) => ('0' + number).slice(-2);

  hours = addZeroIfLessThanTen(hours);
  minutes = addZeroIfLessThanTen(minutes);

  const durationString = `${hours}h ${minutes}m`;
  return durationString;
}

export function formatDate(value: number | Date | undefined): string {
  if (!value || value === undefined) return '-';

  // convert dates to miliseconds
  if (value instanceof Date) value = value.valueOf();

  const formattedDate = moment(value).format('hh:mmA');

  return formattedDate;
}
