import {createSelector} from 'reselect';
import {RootState} from '../reducers/index';

const getTimers = (state: RootState) => state.timers.timers;

export const getUniqueClients = createSelector([getTimers], (timers) => {
  if (timers === undefined) return undefined;

  if (!timers) return [];

  const allClients = timers.map((timer) => timer.data.client);

  const uniqueClients = [...new Set(allClients)];

  return uniqueClients;
});
