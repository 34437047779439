import {TimerActionTypes, CREATE_TIMER, INIT_TIMERS, DELETE_TIMER} from '../types/timers.types';
import {Timer} from '../../models/timer';

export interface TimersState {
  timers: Timer[] | undefined;
}

const initialState: TimersState = {
  timers: undefined,
};

export function timersReducer(state = initialState, action: TimerActionTypes): TimersState {
  switch (action.type) {
    case CREATE_TIMER:
      return {
        timers: state.timers !== undefined ? [action.payload, ...state.timers] : [action.payload],
      };

    case DELETE_TIMER:
      return {
        timers: action.payload ? action.payload : [],
      };

    case INIT_TIMERS:
      return {
        timers: action.payload ? action.payload : [],
      };

    default:
      return state;
  }
}
