import React from 'react';

import styles from './TourModal.module.scss';
import {useTranslation} from 'react-i18next';
import {IonButton, IonLabel} from '@ionic/react';

interface Props {
  startTourAction: () => void;
  closeAction: () => void;
}

const TourModal = (props: Props) => {
  const {t} = useTranslation(['tour', 'common']);
  return (
    <div className={styles.container}>
      <h2>{t('tour:welcome')}</h2>
      <p>{t('tour:welcomesub')}</p>
      <div className="actions">
        <IonButton
          color="card"
          type="submit"
          onClick={() => {
            props.closeAction();
            props.startTourAction();
          }}>
          <IonLabel>{t('tour:start')}</IonLabel>
        </IonButton>
        <IonButton color="primary" type="submit" onClick={props.closeAction}>
          <IonLabel>{t('tour:leave')}</IonLabel>
        </IonButton>
      </div>
    </div>
  );
};

export default TourModal;
