import {get, set, del} from 'idb-keyval';

import {v4 as uuid} from 'uuid';

import {addMinutes, isBefore, lightFormat, roundToNearestMinutes, subMinutes} from 'date-fns';

import {Timer} from '../../models/timer';
import {Entry, EntryData} from '../../models/entry';

import {EntryInProgress, EntryInProgressData} from '../../store/interfaces/entry.inprogress';
import {Settings} from '../../models/settings';

export class EntriesService {
  private static instance: EntriesService;

  private entriesWorker: Worker = new Worker('./workers/entries.js');

  private constructor() {
    // Private constructor, singleton
  }

  static getInstance() {
    if (!EntriesService.instance) {
      EntriesService.instance = new EntriesService();
    }
    return EntriesService.instance;
  }

  start(timer: Timer, settings: Settings): Promise<EntryInProgress> {
    return new Promise<EntryInProgress>(async (resolve, reject) => {
      try {
        await this.stop(settings.roundTime);

        const entry: EntryInProgress = await this.createEntryInProgress(timer, settings);

        await set('entry-in-progress', entry);

        resolve(entry);
      } catch (err) {
        reject(err);
      }
    });
  }

  stop(roundTime: number): Promise<Entry | string> {
    return new Promise<Entry | string>(async (resolve, reject) => {
      try {
        let entry: Entry = await get('entry-in-progress');

        if (!entry || !entry.data) {
          resolve('No entry in progress found.');
          return;
        }

        await this.saveEntryAndInvoice(entry, roundTime, true);

        await del('entry-in-progress');

        resolve(entry);
      } catch (err) {
        reject(err);
      }
    });
  }

  create(entryData: EntryData, roundTime: number): Promise<Entry> {
    return new Promise<Entry>(async (resolve, reject) => {
      try {
        if (!entryData) {
          reject('No entry data provided.');
          return;
        }

        const entry: Entry = {
          id: uuid(),
          data: entryData,
        };

        await this.saveEntryAndInvoice(entry, roundTime, false);

        resolve(entry);
      } catch (err) {
        reject(err);
      }
    });
  }

  private saveEntryAndInvoice(entry: Entry, roundTime: number, endNow: boolean): Promise<Entry> {
    return new Promise<Entry>(async (resolve, reject) => {
      try {
        if (!entry || !entry.data) {
          reject('No entry provided.');
          return;
        }

        const now: Date = new Date();

        entry.data.updated_at = now.getTime();

        const from: Date = roundTime > 0 ? roundToNearestMinutes(entry.data.from, {nearestTo: roundTime}) : new Date(entry.data.from);
        entry.data.from = from.getTime();

        const toCompare: Date = endNow ? now : new Date(entry.data.to as number | Date);
        const to: Date = isBefore(subMinutes(toCompare, roundTime), from) ? addMinutes(from, roundTime) : toCompare;

        entry.data.to = roundTime > 0 ? roundToNearestMinutes(to, {nearestTo: roundTime}).getTime() : to.getTime();

        await this.saveEntry(entry);

        const dayShort: string = lightFormat(entry.data.from, 'yyyy-MM-dd');
        await this.addEntryToInvoices(dayShort);

        resolve(entry);
      } catch (err) {
        reject(err);
      }
    });
  }

  updateEntryInProgress(entry: EntryInProgress): Promise<EntryInProgress | undefined> {
    return new Promise<EntryInProgress | undefined>(async (resolve, reject) => {
      try {
        if (!entry || !entry.data) {
          reject('Entry is not defined.');
          return;
        }

        entry.data.updated_at = new Date().getTime();

        await set('entry-in-progress', entry);

        resolve(entry);
      } catch (err) {
        reject(err);
      }
    });
  }

  current(): Promise<EntryInProgress | undefined> {
    return new Promise<EntryInProgress | undefined>(async (resolve, reject) => {
      try {
        const entry: EntryInProgress = await get('entry-in-progress');

        resolve(entry);
      } catch (err) {
        reject(err);
      }
    });
  }

  private createEntryInProgress(timer: Timer, settings: Settings): Promise<EntryInProgress> {
    return new Promise<EntryInProgress>((resolve, reject) => {
      const isTimerAvailable = timer && timer.data && timer.data.client;
      if (!isTimerAvailable) {
        reject('Timer is missing some data.');
        return;
      }

      const now: number = new Date().getTime();

      // We create an EntryInProgress as, furthermore than being persisted, it's going to be added to the root state too
      let entry: EntryInProgress = {
        id: uuid(),
        data: {
          from: now,
          timer_id: timer.id,
          created_at: now,
          updated_at: now,
          timer: {
            activity: timer.data.activity,
            client: timer.data.client,
            project: timer.data.project,
            billable: timer.data.billable,
            color: timer.data.color,
          },
        },
      };

      resolve(entry);
    });
  }

  private addEntryToInvoices(day: string): Promise<void> {
    return new Promise<void>(async (resolve) => {
      let invoices: string[] = await get('invoices');

      if (invoices && invoices.indexOf(day) > -1) {
        resolve();
        return;
      }

      if (!invoices || invoices.length <= 0) {
        invoices = [];
      }

      invoices.push(day);

      await set('invoices', invoices);

      resolve();
    });
  }

  private saveEntry(entry: Entry): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        if (!entry || !entry.data) {
          reject('Entry is not defined.');
          return;
        }

        const entryToPersist: Entry = {...entry};

        // Clean before save
        delete (entryToPersist.data as EntryInProgressData)['timer'];

        const storeDate: string = lightFormat(entry.data.from, 'yyyy-MM-dd');

        let entries: Entry[] = await get(`entries-${storeDate}`);

        if (!entries || entries.length <= 0) {
          entries = [];
        }

        entries.push(entry);

        await set(`entries-${storeDate}`, entries);

        resolve();
      } catch (err) {
        reject(err);
      }
    });
  }

  list(updateStateFunction: Function, forDate: Date): Promise<void> {
    return new Promise<void>((resolve) => {
      this.entriesWorker.onmessage = ($event: MessageEvent) => {
        if ($event && $event.data) {
          updateStateFunction($event.data, forDate);
        }
      };

      this.entriesWorker.postMessage({msg: 'listEntries', day: lightFormat(forDate, 'yyyy-MM-dd')});

      resolve();
    });
  }

  /**
   * @param entry
   * @param day The store index, like saved in indexDB entries-2019-12-19
   */
  update(entry: Entry | undefined, day: string): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        if (!entry || !entry.data || !day) {
          reject('Entry is not defined.');
          return;
        }

        const entryToPersist: Entry = {...entry};

        // Clean before save
        delete (entryToPersist.data as EntryInProgressData)['timer'];

        const entries: Entry[] = await this.load(day);

        const index: number = entries.findIndex((filteredEntry: Entry) => {
          return filteredEntry.id === entryToPersist.id;
        });

        if (index < 0) {
          reject('Entries not found.');
          return;
        }

        entryToPersist.data.updated_at = new Date().getTime();

        entries[index] = entryToPersist;

        await set(`entries-${day}`, entries);

        await this.addEntryToInvoices(day);

        resolve();
      } catch (err) {
        reject(err);
      }
    });
  }

  delete(entry: Entry | undefined, day: string): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        if (!entry || !entry.data || !day) {
          reject('Entry is not defined.');
          return;
        }

        const entries: Entry[] = await this.load(day);

        const index: number = entries.findIndex((filteredEntry: Entry) => {
          return filteredEntry.id === entry.id;
        });

        if (index < 0) {
          reject('Entries not found.');
          return;
        }

        entries.splice(index, 1);

        await set(`entries-${day}`, entries);

        resolve();
      } catch (err) {
        reject(err);
      }
    });
  }

  private load(day: string): Promise<Entry[]> {
    return new Promise<Entry[]>(async (resolve, reject) => {
      let entries: Entry[] = await get(`entries-${day}`);

      if (!entries || entries.length <= 0) {
        reject('No entries found for the specific day.');
        return;
      }

      resolve(entries);
    });
  }

  find(id: string, day: string): Promise<Entry | undefined> {
    return new Promise<Entry>(async (resolve) => {
      if (!id || id === undefined || !day || day === undefined) {
        resolve(undefined);
        return;
      }

      const entries: Entry[] = await get(`entries-${day}`);

      if (!entries || entries.length <= 0) {
        resolve(undefined);
        return;
      }

      const entry: Entry | undefined = entries.find((filteredEntry: Entry) => {
        return filteredEntry.id === id;
      });

      resolve(entry);
    });
  }
}
