import {RootThunkResult} from './types.thunks';

import {START_ENTRY, STOP_ENTRY, INIT_ENTRY, LIST_ENTRIES, UPDATE_ENTRY, CREATE_ENTRY} from '../types/entries.types';

import {EntryInProgress} from '../interfaces/entry.inprogress';
import {EntryItem} from '../interfaces/entry.item';

import {Timer} from '../../models/timer';
import {Settings} from '../../models/settings';

import {EntriesService} from '../../services/entries/entries.service';
import {NotificationsService} from '../../services/notifications/notifications.service';
import {EntryData} from '../../models/entry';

export function updateEntry(data: EntryInProgress): RootThunkResult<Promise<void>> {
  return async (dispatch, getState) => {
    const entry: EntryInProgress | undefined = await EntriesService.getInstance().updateEntryInProgress(data);

    dispatch({type: UPDATE_ENTRY, payload: entry});
  };
}

export function createEntry(entryData: EntryData, roundTime: number): RootThunkResult<Promise<void>> {
  return async (dispatch, getState) => {
    await EntriesService.getInstance().create(entryData, roundTime);

    dispatch({type: CREATE_ENTRY});
  };
}

export function initEntry(): RootThunkResult<Promise<void>> {
  return async (dispatch, getState) => {
    const entry: EntryInProgress | undefined = await EntriesService.getInstance().current();

    dispatch({type: INIT_ENTRY, payload: entry});
  };
}

export function listEntries(forDate: Date): RootThunkResult<Promise<void>> {
  return async (dispatch, getState) => {
    const updateStateFunction = (data: EntryItem[], forDate: Date) => {
      dispatch({
        type: LIST_ENTRIES,
        payload: {
          items: data,
          forDate: forDate,
        },
      });
    };

    await EntriesService.getInstance().list(updateStateFunction, forDate);
  };
}

export function stopEntry(roundTime: number): RootThunkResult<Promise<void>> {
  return async (dispatch, getState) => {
    await EntriesService.getInstance().stop(roundTime);

    await NotificationsService.getInstance().cancel();

    dispatch({type: STOP_ENTRY});
    dispatch(listEntries(getState().entries.entryItemsSelectedDate));
  };
}

export function startEntry(timer: Timer, settings: Settings): RootThunkResult<Promise<void>> {
  return async (dispatch, getState) => {
    const entry: EntryInProgress = await EntriesService.getInstance().start(timer, settings);

    await NotificationsService.getInstance().schedule(timer, settings);

    dispatch({type: START_ENTRY, payload: entry});
    dispatch(listEntries(getState().entries.entryItemsSelectedDate));
  };
}
