import {EntryInProgress} from '../interfaces/entry.inprogress';
import {EntryItem} from '../interfaces/entry.item';

export const START_ENTRY = 'START_ENTRY';
export const UPDATE_ENTRY = 'UPDATE_ENTRY';
export const STOP_ENTRY = 'STOP_ENTRY';
export const INIT_ENTRY = 'INIT_ENTRY';
export const CREATE_ENTRY = 'CREATE_ENTRY';

export const LIST_ENTRIES = 'LIST_ENTRIES';

interface TrackEntryAction {
  type: typeof START_ENTRY | typeof INIT_ENTRY | typeof UPDATE_ENTRY;
  payload: EntryInProgress | undefined;
}

interface StopEntryAction {
  type: typeof STOP_ENTRY;
}

interface CreateEntryAction {
  type: typeof CREATE_ENTRY;
}

interface ListEntriesActionPayload {
  items: EntryItem[];
  forDate: Date;
}

interface ListEntriesAction {
  type: typeof LIST_ENTRIES;
  payload: ListEntriesActionPayload;
}

export type EntryActionTypes = TrackEntryAction | StopEntryAction | CreateEntryAction;

export type EntriesActionTypes = ListEntriesAction;
