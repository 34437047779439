import React, {useState, useEffect} from 'react';

import {useSelector} from 'react-redux';

import {getActiveTimers} from '../../store/selectors/timers.selector';

import {RouteComponentProps} from 'react-router';

import exitIntent from 'exit-intent';

import {useCookies} from 'react-cookie';

import {IonContent, IonPage, IonModal, IonToast, IonLoading, IonButton, IonLabel, IonRow, IonGrid} from '@ionic/react';

import {useTranslation} from 'react-i18next';

import Tour from 'reactour';

import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';

import {TIMERS_LIMIT, TIME_UNITS} from '../../constants/index';

import CreateTimerModal from '../../modals/timer/CreateTimerModal';
import FeedbackModal from '../../modals/feedback/FeedbackModal';
import TourModal from '../../modals/tour/TourModal';

import Header from '../../components/header/Header';
import Timers from '../../components/timers/Timers';
import Tracker from '../../components/tracker/Tracker';
import Entries from '../../components/entries/Entries';

import {Timer} from '../../models/timer';
import {Entry} from '../../models/entry';
import {EntryInProgress} from '../../store/interfaces/entry.inprogress';
import {RootState} from '../../store/reducers';

const {MILLISECONDS_IN_SECONDS, SECONDS_IN_MINUTE, MINUTES_IN_HOUR} = TIME_UNITS;

const Home: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const {t} = useTranslation(['home', 'common', 'feedback', 'tour']);

  const [cookies, setCookie] = useCookies(['sent-feedback', 'registered', 'displayed-feedback']);

  const timers: Timer[] | undefined = useSelector((state: RootState) => state.timers.timers);
  const activeTimers: Timer[] | undefined = useSelector(getActiveTimers);
  const entries: Entry[] | undefined = useSelector((state: RootState) => state.entries.entryItems);
  const entryInProgress: EntryInProgress | undefined = useSelector((state: RootState) => state.entries.entryInProgress);

  const [showModalTimer, setShowModalTimer] = useState(false);

  const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false);

  const [isThanksToastVisible, setIsThanksToastVisible] = useState(false);

  const [isLimitToastVisible, setIsLimitToastVisible] = useState(false);

  const [isTourOpen, setIsTourOpen] = useState(false);

  const [isTouring, setIsTouring] = useState(false);

  const [isTourModalVisible, setIsTourModalVisible] = useState(false);

  const [step, setStep] = useState(0);

  const [entriesElement, setEntriesElement] = useState<HTMLDivElement | undefined>(undefined);

  useEffect(() => {
    const shouldShowTour = !cookies['registered'] && timers !== undefined;

    if (shouldShowTour) {
      setIsTourModalVisible(true);
      setCookie('registered', true);
    }
  }, [cookies, setCookie, timers]);

  const handleClickOnTourFinish = () => {
    setIsTourOpen(false);
    setIsTouring(false);
  };

  const lastStepContent = (
    <IonGrid>
      <IonRow>
        <span>{t('tour:entries')}</span>
      </IonRow>
      <IonRow className="ion-justify-content-end ion-margin-top">
        <IonButton color="primary" onClick={handleClickOnTourFinish}>
          <IonLabel>{t('common:actions.finish')}</IonLabel>
        </IonButton>
      </IonRow>
    </IonGrid>
  );

  const steps = [
    {
      selector: '.tour-first-step',
      content: t('tour:createtimer'),
    },
    {
      selector: '.tour-second-step',
      content: t('tour:timerform'),
    },
    {selector: '.tour-third-step', content: t('tour:timers')},
    {selector: '.tour-fourth-step', content: t('tour:tracker')},
    {selector: '.tour-fifth-step', content: lastStepContent},
  ];

  const areTimersEmpty = timers !== undefined && timers.length <= 0;
  const areEntriesEmpty = entries !== undefined && entries.length <= 0 && !entryInProgress;

  const displayModalTimer = () => {
    const areTimersLimitReached = activeTimers.length >= TIMERS_LIMIT;
    if (areTimersLimitReached) {
      setIsLimitToastVisible(true);
      return;
    }

    setShowModalTimer(true);
  };

  const handleCreateTimerModalDismiss = () => {
    setShowModalTimer(false);

    if (isTouring && !isTourOpen) {
      setIsTourOpen(true);
      setStep(step + 1);
    }
  };

  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);

  const handleTourLastStep = (currentStep: number) => {
    if (currentStep + 1 === steps.length) {
      entriesElement.scrollIntoView({behavior: 'smooth'});
    }
  };

  const handleExitIntent = () => {
    const hasSentFeedback = cookies['sent-feedback'] === 'true';
    const hasDisplayedFeedbackInThePastHour = cookies['displayed-feedback'] === 'true';

    if (hasDisplayedFeedbackInThePastHour || isTourModalVisible || isFeedbackModalVisible || isTouring || hasSentFeedback) return;

    setIsFeedbackModalVisible(true);
    setCookie('displayed-feedback', 'true', {expires: new Date(Date.now() + MILLISECONDS_IN_SECONDS * SECONDS_IN_MINUTE * MINUTES_IN_HOUR)});
  };

  exitIntent({
    threshold: 10,
    maxDisplays: 1,
    eventThrottle: 0,
    onExitIntent: handleExitIntent,
  });

  return (
    <>
      <Tour
        showCloseButton={step === 1}
        showButtons={false}
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
        steps={steps}
        isOpen={isTourOpen}
        goToStep={step}
        onRequestClose={() => setIsTourOpen(false)}
        closeWithMask={false}
        disableKeyboardNavigation
        disableDotsNavigation
        getCurrentStep={handleTourLastStep}
      />

      <IonToast
        isOpen={isLimitToastVisible}
        onDidDismiss={() => setIsLimitToastVisible(false)}
        message={`You can't make more than ${TIMERS_LIMIT} timers!`}
        duration={400}
      />

      <IonToast isOpen={isThanksToastVisible} onDidDismiss={() => setIsThanksToastVisible(false)} message={t('feedback:success')} duration={800} />

      <IonLoading mode="ios" isOpen={timers === undefined} message={t('common:actions.wait')} />

      <IonModal isOpen={showModalTimer} onDidDismiss={handleCreateTimerModalDismiss} cssClass="fullscreen">
        <CreateTimerModal closeAction={handleCreateTimerModalDismiss} isTouring={isTouring} />
      </IonModal>

      <IonModal mode="ios" isOpen={isFeedbackModalVisible} onDidDismiss={() => setIsFeedbackModalVisible(false)} cssClass="fullscreen">
        <FeedbackModal closeAction={() => setIsFeedbackModalVisible(false)} showThanksToastAction={() => setIsThanksToastVisible(true)} />
      </IonModal>

      <IonModal mode="ios" isOpen={isTourModalVisible} onDidDismiss={() => setIsTourModalVisible(false)}>
        <TourModal
          closeAction={() => setIsTourModalVisible(false)}
          startTourAction={() => {
            setIsTourOpen(true);
            setIsTouring(true);
          }}
        />
      </IonModal>

      <IonPage>
        <IonContent>
          <Header />

          <main className="ion-padding">
            <div className="tour-fourth-step">
              <Tracker
                areTimersEmpty={areTimersEmpty}
                areEntriesEmpty={areEntriesEmpty}
                isLoading={timers === undefined}
                nextStepAction={() => isTouring && setStep(step + 1)}
              />
            </div>

            <Timers addAction={displayModalTimer} areTimersEmpty={areTimersEmpty} areEntriesEmpty={areEntriesEmpty} nextStepAction={() => setStep(step + 1)} />

            <div className="tour-fifth-step" ref={(el) => setEntriesElement(el)}>
              <Entries />
            </div>
          </main>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Home;
