import React, {useState} from 'react';

import {IonButton, IonIcon, IonLabel, IonAlert, IonLoading, IonToast} from '@ionic/react';

import {downloadOutline} from 'ionicons/icons';

import {useTranslation} from 'react-i18next';

import {BackupService} from '../../services/backup/backup.service';

const ExportButton = () => {
  const {t} = useTranslation(['backup', 'common']);

  const [isExportAlertVisible, setIsExportAlertVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorToastVisible, setIsErrorToastVisible] = useState(false);

  const doExport = async () => {
    setIsLoading(true);

    try {
      await BackupService.getInstance().backup();
    } catch (error) {
      console.error(error);
      setIsErrorToastVisible(true);
    }

    setIsLoading(false);
  };

  return (
    <>
      <IonToast isOpen={isErrorToastVisible} onDidDismiss={() => setIsErrorToastVisible(false)} message={t('common:feedback.error')} duration={400} />

      <IonAlert
        isOpen={isExportAlertVisible}
        onDidDismiss={() => setIsExportAlertVisible(false)}
        header={t('backup:title')}
        message={t('backup:message')}
        buttons={[
          {
            text: t('common:actions.cancel'),
            role: 'cancel',
            handler: () => setIsExportAlertVisible(false),
          },
          {
            text: t('common:actions.export'),
            handler: async () => {
              await doExport();
            },
          },
        ]}
      />

      <IonLoading isOpen={isLoading} message={t('common:actions.wait')} />

      <IonButton fill="outline" color="medium" size="small" onClick={() => setIsExportAlertVisible(true)}>
        <IonIcon icon={downloadOutline} />
        <IonLabel className="ion-padding">{t('common:actions.export')}</IonLabel>
      </IonButton>
    </>
  );
};

export default ExportButton;
