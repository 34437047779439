import React from 'react';

import {IonChip, IonLabel, IonIcon} from '@ionic/react';

import styles from './DetailsChip.module.scss';

import {contrast} from '../../../utils/utils.color';

interface Props {
  color: string;
  textSize: string | undefined;
  icon: string;
  text: string;
}

const DetailsChip = ({color, textSize, icon, text}: Props) => {
  const contrastColor = contrast(color !== undefined ? color : undefined);

  return (
    <IonChip mode="ios" style={{'--background': color, '--color': contrastColor}} className={styles.chip}>
      <IonIcon style={{color: contrastColor, fontSize: textSize}} icon={icon} />
      <IonLabel style={{fontSize: textSize}}>{text}</IonLabel>
    </IonChip>
  );
};

export default DetailsChip;
