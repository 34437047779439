import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';

import DetailsChip from '../generic/detailschip/DetailsChip';

import {IonToast, IonTitle, IonLabel, IonIcon, IonRow, IonToolbar} from '@ionic/react';
import {stopCircle, pricetag, rocket, person, construct} from 'ionicons/icons';

import styles from './Tracker.module.scss';

import {differenceInSeconds} from 'date-fns';

import {EntryInProgress} from '../../store/interfaces/entry.inprogress';
import {Settings} from '../../models/settings';
import {RootState} from '../../store/reducers';

import {rootConnector, RootProps} from '../../store/thunks/index.thunks';

interface Props extends RootProps {
  nextStepAction: Function;
  areTimersEmpty: boolean;
  areEntriesEmpty: boolean;
  isLoading: boolean;
}

const Tracker: React.FC<Props> = (props: Props) => {
  const entryInProgress: EntryInProgress | undefined = useSelector((state: RootState) => state.entries.entryInProgress);
  const settings: Settings = useSelector((state: RootState) => state.settings.settings);

  const [timeElapsed, setTimeElapsed] = useState<string>('00:00:00');

  const [isErrorToastVisible, setIsErrorToastVisible] = useState(false);

  useEffect(function updateTimeElapsed() {
    const progressInterval = window.setInterval(async () => {
      if (entryInProgress && entryInProgress.data) {
        const now: Date = new Date();

        const seconds: number = differenceInSeconds(now, entryInProgress.data.from);

        setTimeElapsed(await formatTime(seconds));
      }
    }, 1000);

    // eslint-disable-next-line
    return () => clearInterval(progressInterval);
  });

  useEffect(
    function resetTimeElapsedOnEntryInProgressChange() {
      setTimeElapsed('00:00:00');
    },
    [entryInProgress]
  );

  const stopEntry = async () => {
    try {
      await props.stopEntry(settings.roundTime);
    } catch (err) {
      setIsErrorToastVisible(true);
      console.error(err);
    }
  };

  const formatTime = async (seconds: number): Promise<string> => {
    const diffHours: number = Math.floor(seconds / 3600);
    seconds = seconds % 3600;
    const diffMinutes: number = Math.floor(seconds / 60);
    const diffSeconds: number = seconds % 60;

    return `${diffHours >= 99 ? '99' : diffHours < 10 ? '0' + diffHours : diffHours}:${diffMinutes < 10 ? '0' + diffMinutes : diffMinutes}:${
      diffSeconds < 10 ? '0' + diffSeconds : diffSeconds
    }`;
  };

  const handleClickOnStopIcon = () => {
    props.nextStepAction();
    stopEntry();
  };

  const isEntryInProgressAvailable = entryInProgress && entryInProgress.data && entryInProgress.data.timer;

  return (
    <div className="ion-padding-bottom ion-padding-top">
      <IonToast
        isOpen={isErrorToastVisible}
        onDidDismiss={() => setIsErrorToastVisible(false)}
        message="Failed to stop the entry. Someting went wrong!"
        duration={400}
      />
      <IonToolbar color="card" className={`tracker-container ${styles.tracker}`}>
        {isEntryInProgressAvailable ? renderTracker() : renderEmptyTracker()}
      </IonToolbar>
    </div>
  );

  function renderTracker() {
    const {color, activity, billable, client, project} = entryInProgress.data.timer;

    return (
      <>
        <div className={styles.desktopTracker}>
          <IonTitle slot="start">{activity}</IonTitle>
          <IonRow className="ion-justify-content-end ion-align-items-center" slot="end">
            <DetailsChip color={color} textSize="12px" icon={rocket} text={project} />
            <DetailsChip color={color} textSize="12px" icon={person} text={client} />
            {billable && <IonIcon className={`billable-icon ion-margin-start  ${styles.billableIcon}`} icon={pricetag} />}
            <IonLabel className="ion-margin-start ion-margin-end">{timeElapsed}</IonLabel>
            <IonIcon onClick={handleClickOnStopIcon} className="stop-timer-icon" icon={stopCircle} />
          </IonRow>
        </div>
        <div className={styles.mobileTracker}>
          <div className={styles.leftTrackerContainer}>
            <IonTitle className="ion-margin-bottom">{timeElapsed}</IonTitle>
            <div className={`${styles.trackerChipsContainer} ion-margin-bottom`}>
              <DetailsChip color={color} textSize="12px" icon={construct} text={activity} />
              <DetailsChip color={color} textSize="12px" icon={rocket} text={project} />
              <DetailsChip color={color} textSize="12px" icon={person} text={client} />
            </div>
            {billable && <IonIcon className={`billable-icon ion-margin-start  ${styles.billableIcon}`} icon={pricetag} />}
          </div>
          <div className={styles.rightTrackerContainer}>
            <IonIcon onClick={handleClickOnStopIcon} className="stop-timer-icon" icon={stopCircle} />
          </div>
        </div>
      </>
    );
  }

  function renderEmptyTracker() {
    return <IonLabel className="ion-margin-start ion-margin-end">{getEmptyTrackerText()}</IonLabel>;
  }

  function getEmptyTrackerText() {
    if (props.isLoading) return 'Loading...';
    if (props.areTimersEmpty) return 'No active timers found. Please create a new timer.';
    return 'No timer is active at the moment. Click on the timers to start a new entry.';
  }
};

export default rootConnector(Tracker);
