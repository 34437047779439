import {CREATE_ENTRY, INIT_ENTRY, LIST_ENTRIES, START_ENTRY, STOP_ENTRY, EntryActionTypes, EntriesActionTypes, UPDATE_ENTRY} from '../types/entries.types';

import {EntryItem} from '../interfaces/entry.item';
import {EntryInProgress} from '../interfaces/entry.inprogress';

export interface EntryState {
  entryInProgress: EntryInProgress | undefined;
  entryItems: EntryItem[];
  entryItemsSelectedDate: Date;
}

const initialState: EntryState = {
  entryInProgress: undefined,
  entryItems: [] as EntryItem[],
  entryItemsSelectedDate: new Date(),
};

export function entriesReducer(state = initialState, action: EntryActionTypes | EntriesActionTypes): EntryState {
  switch (action.type) {
    case INIT_ENTRY:
    case UPDATE_ENTRY:
    case START_ENTRY:
      return {
        entryInProgress: action.payload ? {...action.payload} : undefined,
        entryItems: state.entryItems,
        entryItemsSelectedDate: state.entryItemsSelectedDate,
      };
    case STOP_ENTRY:
    case CREATE_ENTRY:
      return {
        entryInProgress: undefined,
        entryItems: state.entryItems,
        entryItemsSelectedDate: state.entryItemsSelectedDate,
      };
    case LIST_ENTRIES:
      return {
        entryInProgress: state.entryInProgress,
        entryItems: [...action.payload.items],
        entryItemsSelectedDate: action.payload.forDate,
      };
    default:
      return state;
  }
}
