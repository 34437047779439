import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {getActiveTimers} from '../../store/selectors/timers.selector';

import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonIcon,
  IonRippleEffect,
  IonLabel,
  IonToast,
  IonChip,
  IonAlert,
  IonButton,
} from '@ionic/react';
import {play, addCircleOutline, trash, heart, pricetag, stop, rocket, construct} from 'ionicons/icons';

import styles from './Timers.module.scss';

import {Timer} from '../../models/timer';
import {Settings} from '../../models/settings';
import {EntryInProgress} from '../../store/interfaces/entry.inprogress';
import {RootState} from '../../store/reducers';

import {rootConnector, RootProps} from '../../store/thunks/index.thunks';
import {contrast} from '../../utils/utils.color';
import {useTranslation} from 'react-i18next';

interface Props extends RootProps {
  addAction: Function;
  nextStepAction: Function;
  areEntriesEmpty: boolean;
  areTimersEmpty: boolean;
}

const Timers: React.FC<Props> = (props: Props) => {
  const {t} = useTranslation('timers');

  const activeTimers: Timer[] | undefined = useSelector(getActiveTimers);
  const entryInProgress: EntryInProgress | undefined = useSelector((state: RootState) => state.entries.entryInProgress);
  const settings: Settings = useSelector((state: RootState) => state.settings.settings);
  const [isErrorToastVisible, setIsErrorToastVisible] = useState(false);
  const [timerToBeDeleted, setTimerToBeDeleted] = useState<Timer | undefined>(undefined);

  const checkIfTimerIsRunning = (timerId: string) => entryInProgress?.data?.timer_id === timerId;

  const startStopEntry = async (timer: Timer) => {
    try {
      if (checkIfTimerIsRunning(timer.id)) {
        await props.stopEntry(settings.roundTime);
      } else {
        await props.startEntry(timer, settings);
      }
    } catch (err) {
      console.error(err);
      setIsErrorToastVisible(true);
    }
  };

  const _setTimerToBeDeleted = ($event: React.MouseEvent, timer: Timer) => {
    $event.stopPropagation();
    setTimerToBeDeleted(timer);
  };

  const deleteTimer = async () => {
    if (!timerToBeDeleted) {
      setIsErrorToastVisible(true);
      return;
    }

    try {
      await props.deleteTimer(timerToBeDeleted);
    } catch (err) {
      console.error(err);
      setIsErrorToastVisible(true);
    }

    setTimerToBeDeleted(undefined);
  };

  return (
    <div className="ion-padding-bottom ion-padding-top tour-third-step">
      <IonAlert
        isOpen={!!timerToBeDeleted}
        onDidDismiss={() => setTimerToBeDeleted(undefined)}
        header="Are you sure you want to delete this timer?"
        message="Information of the timer will be inaccessible forever!"
        buttons={[
          {
            text: 'Cancel',
            handler: () => setTimerToBeDeleted(undefined),
          },
          {
            text: 'Delete',
            handler: () => deleteTimer(),
          },
        ]}
      />

      <IonToast
        isOpen={isErrorToastVisible}
        onDidDismiss={() => setIsErrorToastVisible(false)}
        message="Failed to create a new entry. Someting went wrong!"
        duration={400}
      />

      <div className={`${styles.titleContainer}`}>
        <h1 className="ion-no-margin">{t('timers.title')}</h1>
        <IonButton
          onClick={() => {
            props.addAction();
            props.nextStepAction();
          }}
          className={`${props.areTimersEmpty ? 'start-animation' : undefined}`}
          style={activeTimers === undefined ? {opacity: 0} : undefined}
          size="small"
          fill="outline"
          color="medium">
          <div className="tour-first-step">
            <IonIcon icon={addCircleOutline} size="small" className="ion-margin-end" />
            <IonLabel>{t('create.title')}</IonLabel>
          </div>
        </IonButton>
      </div>
      {props.areTimersEmpty ? <p>{t('timers.empty')}</p> : <div className={styles.timers}>{renderTimers()}</div>}
    </div>
  );

  function renderTimers() {
    return (
      activeTimers &&
      activeTimers.map((timer: Timer) => {
        const colorContrast: string = contrast(timer.data?.color);

        return (
          <IonCard
            key={timer.id}
            onClick={() => {
              startStopEntry(timer);
              props.nextStepAction();
            }}
            className="ion-activatable client ion-no-margin"
            color="card">
            <div
              className="card-head"
              style={{
                background: timer.data?.color,
                color: colorContrast,
              }}>
              <IonLabel>{checkIfTimerIsRunning(timer.id) ? 'Stop' : 'Start'}</IonLabel>
              <IonIcon icon={checkIfTimerIsRunning(timer.id) ? stop : play} />
            </div>
            <IonCardHeader>
              <IonCardTitle>{timer.data.client}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <div className={styles.chipsContainer}>
                <IonChip mode="ios" className={styles.detailsChip}>
                  <IonIcon className="billable-icon" icon={rocket} />
                  <span>{timer.data.project}</span>
                </IonChip>
                <IonChip mode="ios" className={styles.detailsChip}>
                  <IonIcon className="billable-icon" icon={construct} />
                  <span>{timer.data.activity}</span>
                </IonChip>
              </div>
              <IonChip mode="ios" className={styles.detailsChip}>
                <IonIcon className="billable-icon" icon={timer.data?.billable ? pricetag : heart} />
                <span>{timer.data?.billable ? 'Billable' : 'Unbillable'}</span>
              </IonChip>
              <br />
              <IonIcon
                onClick={($event) => _setTimerToBeDeleted($event, timer)}
                className={styles.trashIcon}
                icon={trash}
              />
            </IonCardContent>
            <IonRippleEffect></IonRippleEffect>
          </IonCard>
        );
      })
    );
  }
};

export default rootConnector(Timers);
