import React from 'react';

import {IonItem, IonLabel, IonRow, IonCol, IonIcon} from '@ionic/react';

import DetailsChip from '../generic/detailschip/DetailsChip';

import {pricetag, rocket, person} from 'ionicons/icons';

import {useTranslation} from 'react-i18next';

import styles from './EntryItem.module.scss';

import {EntryItem as EntryItemStore} from '../../store/interfaces/entry.item';
import {rootConnector, RootProps} from '../../store/thunks/index.thunks';

import {formatDate, formatDuration} from '../../utils/utils.time';

interface EntryItemProps extends RootProps {
  entry: EntryItemStore;
  entriesDay: string | undefined;
}

const EntryItem: React.FC<EntryItemProps> = (props) => {
  const {t} = useTranslation('entries');

  const isTimerAvailable = props.entry.data?.timer;
  if (!isTimerAvailable) return null;
  return renderItem();

  function renderItem() {
    const {timer} = props.entry.data;

    return (
      <IonItem className={styles.item} color="card">
        <IonRow>
          <IonCol size="auto">
            <IonLabel className="ion-margin-end">{timer.activity ? timer.activity : t('item.no_activity')}</IonLabel>
            <DetailsChip color={timer.color} icon={person} textSize="12px" text={timer.client ? timer.client : t('item.no_client')} />
            <DetailsChip color={timer.color} icon={rocket} textSize="12px" text={timer.project ? timer.project : t('item.no_project')} />
            {timer.billable && <IonIcon className="ion-margin-horizontal" icon={pricetag} />}
          </IonCol>
          <IonCol size="auto" className={`ion- ${styles.shiftToEnd}`}>
            <IonLabel className="ion-margin-horizontal">{`${formatDate(props.entry.data.from)} - ${formatDate(props.entry.data.to)}`}</IonLabel>
            <IonLabel className="ion-margin-end">{formatDuration(props.entry.data.milliseconds)}</IonLabel>
          </IonCol>
        </IonRow>
      </IonItem>
    );
  }
};

export default rootConnector(EntryItem);
