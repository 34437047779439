import {Timer} from '../../models/timer';

export const CREATE_TIMER = 'CREATE_TIMER';
export const INIT_TIMERS = 'INIT_TIMERS';
export const DELETE_TIMER = 'DELETE_TIMER';

interface CreateTimerAction {
  type: typeof CREATE_TIMER;
  payload: Timer;
}

interface initTimersAction {
  type: typeof INIT_TIMERS;
  payload: Timer[];
}

interface deleteTimerAction {
  type: typeof DELETE_TIMER;
  payload: Timer[];
}

export type TimerActionTypes = CreateTimerAction | initTimersAction | deleteTimerAction;
