import {FeedbackFormInfo} from '../../models/feedback';

const axios = require('axios');

export class FeedbackService {
  private static instance: FeedbackService;

  private constructor() {
    // Private constructor, singleton
  }

  static getInstance() {
    if (!FeedbackService.instance) {
      FeedbackService.instance = new FeedbackService();
    }
    return FeedbackService.instance;
  }

  async submit({rating, email, feedback}: FeedbackFormInfo) {
    const url = new URL('https://hula-functions-prd.azurewebsites.net/api/SendFeedbackFunction');
    url.searchParams.append('code', 'fDH99pjb2U0lX6W8dA6DvuXKyIpFlkWJdtFHlrzXBpEwiXs5KmB1zg==');

    const data = {
      numberOfStars: rating,
      note: feedback,
      email,
    };

    return await axios.post(url.toString(), data);
  }
}
