import {RootThunkResult} from './types.thunks';

import {Timer, TimerData} from '../../models/timer';

import {CREATE_TIMER, INIT_TIMERS, DELETE_TIMER} from '../types/timers.types';

import {TimersService} from '../../services/timers/timers.service';

export function createTimer(data: TimerData): RootThunkResult<Promise<void>> {
  return async (dispatch, getState) => {
    const timer: Timer = await TimersService.getInstance().create(data);

    dispatch({type: CREATE_TIMER, payload: timer});
  };
}

export function deleteTimer(data: Timer): RootThunkResult<Promise<void>> {
  return async (dispatch, getState) => {
    const timers: Timer[] = await TimersService.getInstance().delete(data);

    dispatch({type: DELETE_TIMER, payload: timers});
  };
}

export function initTimers(): RootThunkResult<Promise<void>> {
  return async (dispatch, getState) => {
    const timers: Timer[] = await TimersService.getInstance().list();

    dispatch({type: INIT_TIMERS, payload: timers});
  };
}
