import {isPlatform} from '@ionic/react';

import i18next from 'i18next';

import {Timer} from '../../models/timer';

import {Settings} from '../../models/settings';

import {LocalNotifications, ELocalNotificationTriggerUnit} from '@ionic-native/local-notifications';

export class NotificationsService {
  private static instance: NotificationsService;

  private constructor() {
    // Private constructor, singleton
  }

  static getInstance() {
    if (!NotificationsService.instance) {
      NotificationsService.instance = new NotificationsService();
    }
    return NotificationsService.instance;
  }

  schedule(timer: Timer, settings: Settings): Promise<void> {
    return new Promise<void>(async (resolve) => {
      if (!isPlatform('hybrid')) {
        resolve();
        return;
      }

      const isTimerAvailable = timer && timer.data;
      if (!isTimerAvailable) {
        resolve();
        return;
      }

      const isSettingsAvailable = settings && settings.notifications;
      if (!isSettingsAvailable) {
        resolve();
        return;
      }

      await i18next.loadNamespaces('notifications');

      LocalNotifications.schedule({
        title: timer.data.client ? `${timer.data.client}` : i18next.t('notifications:fallback_title'),
        text: i18next.t('notifications:body', {timer: timer.data.activity}),
        trigger: {
          every: ELocalNotificationTriggerUnit.HOUR,
        },
      });

      resolve();
    });
  }

  cancel(): Promise<void> {
    return new Promise<void>(async (resolve) => {
      if (!isPlatform('hybrid')) {
        resolve();
        return;
      }

      await LocalNotifications.cancelAll();

      resolve();
    });
  }
}
