import React, {Suspense, useEffect} from 'react';
import {Redirect, Route} from 'react-router-dom';
import {IonApp, IonRouterOutlet} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import Home from './pages/home/Home';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.scss';

import './theme/content.scss';
import './theme/header.scss';
import './theme/input.scss';
import './theme/button.scss';
import './theme/modal.scss';
import './theme/fonts.scss';
import './theme/card.scss';
import './theme/datetime.picker.scss';
import './theme/spinner.scss';
import './theme/checkbox-toggle.scss';
import './theme/reorder.scss';
import './theme/searchbar.scss';
import './theme/tabs.scss';
import './theme/segment.scss';
import './theme/alert.scss';
import './theme/toolbar.scss';
import './theme/link.scss';
import './theme/loading.scss';
import './theme/charts.scss';
import './theme/icons.scss';
import './theme/animations.scss';

import './helpers/i18n';

import {RootProps, rootConnector} from './store/thunks/index.thunks';

import {Plugins} from '@capacitor/core';

const {SplashScreen} = Plugins;

const App: React.FC<RootProps> = (props: RootProps) => {
  async function init() {
    // Init theme first
    await props.initTheme();

    await SplashScreen.hide();

    // Init data
    const promises = [];
    promises.push(props.initTimers());
    promises.push(props.initEntry());
    promises.push(props.listEntries(new Date()));
    promises.push(props.initSettings());

    await Promise.all(promises);
  }

  useEffect(() => {
    init();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suspense fallback={'Loading...'}>
      <IonApp>
        <IonReactRouter>
          <IonRouterOutlet>
            <Route path="/home" component={Home} />
            <Route path="/" render={() => <Redirect to="/home" />} exact={true} />
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    </Suspense>
  );
};

export default rootConnector(App);
