import {createSelector} from 'reselect';
import {RootState} from '../reducers/index';

const getTimers = (state: RootState) => state.timers.timers;

export const getUniqueProjects = createSelector([getTimers], (timers) => {
  if (timers === undefined) return undefined;

  if (!timers) return [];

  const allProjects = timers.map((timer) => timer.data.project);

  const uniqueProjects = [...new Set(allProjects)];

  return uniqueProjects;
});
