import {format} from 'date-fns';

import i18next from 'i18next';

import {download} from '../../utils/utils.filesystem';
import {xlsxLabels} from '../../utils/utils.export';

export class BackupService {
  private static instance: BackupService;

  private backupWorker: Worker = new Worker('./workers/backup.js');

  private constructor() {
    // Private constructor, singleton
  }

  static getInstance() {
    if (!BackupService.instance) {
      BackupService.instance = new BackupService();
    }
    return BackupService.instance;
  }

  async backup() {
    await this.exportDownload();
  }

  exportDownload(): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const filename: string = this.filename();

        this.backupWorker.onmessage = async ($event: MessageEvent) => download(filename, $event.data);

        await this.postMessage();

        resolve();
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });
  }

  private filename(): string {
    return `Time_Tracker-Export-${format(new Date(), 'yyyy-MM-dd')}.xlsx`;
  }

  private async postMessage() {
    await i18next.loadNamespaces('export');

    this.backupWorker.postMessage({
      msg: 'backup',
      i18n: xlsxLabels(),
    });
  }
}
